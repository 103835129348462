
import NormalLogin from '@/pages/login/Login.normal.vue'
import LiteLogin from '@/pages/login/Login.lite.vue'
import { defineComponent } from 'vue'
import { flag } from '@/config'

export default defineComponent({
  name: 'Login',
  setup () {
    return {
      Comp: flag.isLite ? LiteLogin : NormalLogin,
    }
  },
})
