/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   Login.vue of WeTrade
*/
<template>
  <Page class="fill px-16 pt-24 df-col df-between">
    <div>
      <div class="df-middle">
        <Pic src="logo_&_text" width="126" height="32" />
        <div class="flex-1"></div>
        <router-link v-if="locale" to="/setting/locale" class="f-md c-title df-middle">
          <Pic :src="`flag/${locale.flag}`" width="16" height="16" />
          <span class="mx-4">{{locale.label}}</span>
          <Icon name="caret-down" sm />
        </router-link>
      </div>
      <div v-if="bannerConfirmed" class="banner-wrap">
        <Swipe #="{item}" :list="banners" :dot="true" dotDark>
          <div class="s-item mt-32">
            <Pic :src="item.imageurl" style="width: 263px; height: 230px; object-fit: cover;" />
            <t as="p" :path="item.text" class="c-text f-lg mt-24 px-8" style="font-weight: bold;"></t>
          </div>
        </Swipe>
      </div>
    </div>
    <div class="btns-">
      <Button class="primary f-bold f-lg block br-lg" @click="pageToLogin(0)">
        <t path="cover_2">Sign Up</t> <t path="cover_3" class="f-md" style="font-weight: 400;">within 10 seconds</t>
      </Button>
      <GoogleLoginBtn class="br-lg" primary />
      <div class="btm-btn d-f df-middle my-12">
        <t as="p" path="cover_5" class="bmbts bmbts-f" @click="pageToLogin(1)">Sign In</t>
        <t as="p" path="cover_6" class="bmbts" @click="roam">Try now</t>
      </div>
    </div>
  </Page>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import { isShield } from '@/decorators/withoutCryptos'
import GoogleLoginBtn from '@/features/googleLogin/GoogleLoginBtn.vue'
import i18n, { languages } from '@/i18n'
import { loginFormSchema, useLogin } from '@/pages/login/login'
import Page from '@/provider/Page.vue'
import { roam } from '@/state/actions'
import Pic from '@/components/Pic.vue'
import { computed, defineComponent, shallowRef } from 'vue'
import { useRouter } from 'vue-router'
import * as R from 'ramda'
import Swipe from '@/components/Swipe.vue'

export default defineComponent({
  name: 'Login',
  components: { GoogleLoginBtn, Button, Icon, Pic, Page, Swipe },
  setup () {
    const { login, progress } = useLogin()
    const router = useRouter()
    const bannerConfirmed = shallowRef(false)

    const tryNow = () => {
      roam()
      router.push('/')
    }

    const locale = computed(() => R.find(R.propEq('lang', i18n.locale.value), languages))

    const banners = [
      {
        imageurl: 'login/cover_1',
        text: 'cover_13',
      },
      {
        imageurl: 'login/cover_2',
        text: 'cover_14',
      },
      {
        imageurl: 'login/cover_3',
        text: 'cover_11',
      },
      {
        imageurl: 'login/cover_4',
        text: 'cover_12',
      },
    ]

    isShield().then(result => {
      if (!result) {
        banners.splice(0, 2, ...[
          {
            imageurl: 'login/cover_1_0',
            text: 'cover_9',
          },
          {
            imageurl: 'login/cover_2_0',
            text: 'cover_10',
          },
        ])
      }

      bannerConfirmed.value = true
    })

    return {
      locale,
      progress,
      loginFormSchema,
      banners,
      bannerConfirmed,
      roam: tryNow,
      login,
      pageToLogin (index: number) {
        router.push('/loginSwich/' + index)
      },
    }
  },
})
</script>

<style scoped lang="scss">
.form {
  margin-top: 40px;
}

.banner-wrap {
  height: 360px;
}

.s-item {
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btm-btn {
  height: 48px;
  color: var(--color-title);

  .bmbts {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .bmbts-f {
    border-right: 1px solid var(--color-title);
  }
}
</style>
