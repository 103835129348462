/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   Login.vue of WeTrade
*/
<template>
  <component :is="Comp" />
</template>

<script lang="ts">
import NormalLogin from '@/pages/login/Login.normal.vue'
import LiteLogin from '@/pages/login/Login.lite.vue'
import { defineComponent } from 'vue'
import { flag } from '@/config'

export default defineComponent({
  name: 'Login',
  setup () {
    return {
      Comp: flag.isLite ? LiteLogin : NormalLogin,
    }
  },
})
</script>
