
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import { isShield } from '@/decorators/withoutCryptos'
import GoogleLoginBtn from '@/features/googleLogin/GoogleLoginBtn.vue'
import i18n, { languages } from '@/i18n'
import { loginFormSchema, useLogin } from '@/pages/login/login'
import Page from '@/provider/Page.vue'
import { roam } from '@/state/actions'
import Pic from '@/components/Pic.vue'
import { computed, defineComponent, shallowRef } from 'vue'
import { useRouter } from 'vue-router'
import * as R from 'ramda'
import Swipe from '@/components/Swipe.vue'

export default defineComponent({
  name: 'Login',
  components: { GoogleLoginBtn, Button, Icon, Pic, Page, Swipe },
  setup () {
    const { login, progress } = useLogin()
    const router = useRouter()
    const bannerConfirmed = shallowRef(false)

    const tryNow = () => {
      roam()
      router.push('/')
    }

    const locale = computed(() => R.find(R.propEq('lang', i18n.locale.value), languages))

    const banners = [
      {
        imageurl: 'login/cover_1',
        text: 'cover_13',
      },
      {
        imageurl: 'login/cover_2',
        text: 'cover_14',
      },
      {
        imageurl: 'login/cover_3',
        text: 'cover_11',
      },
      {
        imageurl: 'login/cover_4',
        text: 'cover_12',
      },
    ]

    isShield().then(result => {
      if (!result) {
        banners.splice(0, 2, ...[
          {
            imageurl: 'login/cover_1_0',
            text: 'cover_9',
          },
          {
            imageurl: 'login/cover_2_0',
            text: 'cover_10',
          },
        ])
      }

      bannerConfirmed.value = true
    })

    return {
      locale,
      progress,
      loginFormSchema,
      banners,
      bannerConfirmed,
      roam: tryNow,
      login,
      pageToLogin (index: number) {
        router.push('/loginSwich/' + index)
      },
    }
  },
})
