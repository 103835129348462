/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   Login.vue of WeTrade
*/
<template>
  <Page class="fill px-16 pt-24 df-col df-between lite_login">
    <div>
      <div class="df-middle">
        <div class="flex-1"></div>
        <router-link v-if="locale" to="/setting/locale" class="f-md c-title df-middle">
          <Pic :src="`flag/${locale.flag}`" width="16" height="16" />
          <span class="mx-4">{{locale.label}}</span>
          <Icon name="caret-down" sm />
        </router-link>
      </div>
    </div>
    <div class="btns-">
      <Button class="primary line f-bold f-lg block br-lg" @click="pageToLogin(0)">
        <t path="cover_2">Sign Up</t> <t path="cover_3" class="f-md" style="font-weight: 400;">within 10 seconds</t>
      </Button>
      <GoogleLoginBtn class="br-lg line" primary />
      <div class="btm-btn d-f df-middle my-12">
        <t as="p" path="cover_5" class="bmbts bmbts-f c-primary" @click="pageToLogin(1)">Sign In</t>
        <t as="p" path="cover_6" class="bmbts c-primary" @click="roam">Try now</t>
      </div>
    </div>
  </Page>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import GoogleLoginBtn from '@/features/googleLogin/GoogleLoginBtn.vue'
import i18n, { languages } from '@/i18n'
import { loginFormSchema, useLogin } from '@/pages/login/login'
import Page from '@/provider/Page.vue'
import { roam } from '@/state/actions'
import Pic from '@/components/Pic.vue'
import { computed, defineComponent, shallowRef } from 'vue'
import { useRouter } from 'vue-router'
import * as R from 'ramda'

export default defineComponent({
  name: 'Login',
  components: { GoogleLoginBtn, Button, Icon, Pic, Page },
  setup () {
    const { login, progress } = useLogin()
    const router = useRouter()
    const bannerConfirmed = shallowRef(false)

    const tryNow = () => {
      roam()
      router.push('/')
    }

    const locale = computed(() => R.find(R.propEq('lang', i18n.locale.value), languages))

    return {
      locale,
      progress,
      loginFormSchema,
      bannerConfirmed,
      roam: tryNow,
      login,
      pageToLogin (index: number) {
        router.push('/loginSwich/' + index)
      },
    }
  },
})
</script>

<style scoped lang="scss">
.lite_login {
  background: url(/img/login/bg_lite@2x.png) no-repeat top center;
  background-size: 100%;
}

.form {
  margin-top: 40px;
}

.banner-wrap {
  height: 360px;
}

.s-item {
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btm-btn {
  height: 48px;
  color: var(--color-title);

  .bmbts {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .bmbts-f {
    border-right: 1px solid var(--color-primary);
  }
}
</style>
